<template>
  <div class="pad-home">
    <HeaderBar title="邀请好友明细" :showLeft="true"></HeaderBar>
    <div class="invite-list">
      <!-- <van-list v-model="loading"
                :finished="finished"
                finished-text="已加载全部记录～"
                @load="onLoad"> -->
      <van-list finished-text="已加载全部记录～">
        <div class="invite-list-item"
             v-for="item in inviteData"
             :key="item.ID"
             :title="item">
          <div class="invite-list-item-info">
            <div class="invite-item-tel">{{item.Tel}}</div>
            <div class="invite-item-source">邀请好友注册</div>
          </div>
          <div class="invite-list-item-info text-right">
            <!-- <div class="invite-item-bounty">
              <span>奖励金共计：</span>
              <span class="invite-item-num">$0.0</span>
            </div> -->
            <div class="invite-item-integrals">
              <span>积分共计：</span>
              <span class="invite-item-num">{{item.JifenTotal||0}}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar'
import { List } from 'vant';
import { postinvite } from '@/service/'
import { getStore } from '../../lib/storage';

export default {
  name: 'inviteList',
  components: {
    HeaderBar,
    [List.name]: List
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      inviteData: null
    }
  },
  methods: {
    // onLoad () {
    //   // 异步更新数据
    //   setTimeout(() => {
    //     for (let i = 0; i < 10; i++) {
    //       this.list.push(this.list.length + 1);
    //     }
    //     // 加载状态结束
    //     this.loading = false;

    //     // 数据全部加载完成
    //     if (this.list.length >= 40) {
    //       this.finished = true;
    //     }
    //   }, 500);
    // },

    async getInviteInfo (uid) {
      let res = await postinvite(uid)
      console.log(res)
      if (res.code == 1) {
       this.inviteData = res.data.list
      }
    }
  },
  mounted () {
    let uid = JSON.parse(getStore('userInfo')).ID
    this.getInviteInfo(uid)
  }
}

</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';

.pad-home{
  padding-top: 94px;
}
.invite {
  &-list {
    padding: 20px 30px;
    &-item {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 130px;
      font-size: 30px;
      border-bottom: 2px solid rgba(238, 238, 238, 1);
    }
  }

  &-item {
    &-tel {
      margin-bottom: 10px;
      font-size: 30px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
    }
    &-source {
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 42px;
    }
    &-bounty {
      margin-bottom: 10px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
    }
    &-integrals {
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
    }
    &-num {
      .color(@brand-green);
    }
  }
}
</style>
